import React from "react"
import track from "./track"

const IsiButton = ({pageType}) => {

	const showHideIsi = () => {
		var IsiContainer = document.getElementById('isi');
		if (IsiContainer.classList.contains('open')){
			track(pageType + " ISI", "button click", "hide isi")
			IsiContainer.classList.remove('open', 'scroll');
		} else {
			track(pageType + " ISI", "button click", "show isi")
			IsiContainer.classList.add('open');
			setTimeout(function(){
				IsiContainer.classList.add('scroll');
			},300);
		}
	}

	return (
		<div className="isi-btn" id={`isi-btn-${pageType}`} onClick={showHideIsi}>
			<div className="line one"></div>
			<div className="line two"></div>
		</div>
	)
}

export default IsiButton
