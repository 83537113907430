import React from "react"
import UpperNav from "./upper-nav"
import MainNav from "./main-nav"
// import Indications from "./indications"

const Header = props => {
  const {pageType, sectionClass, thisPageClass} = props
  return (
    <header className={`${sectionClass} ${thisPageClass}`}>
      <UpperNav pageType={pageType}/>
      {/* <Indications pageType={pageType}/> */}
      <MainNav pageType={pageType}/>
    </header>
  )
}

export default Header
