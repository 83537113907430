import React, {useState} from "react"
import movantikNaloxegol from "../images/movantik-naloxegol.png"
import movantikNaloxegolDesktop from "../images/movantik-naloxegol-desktop.png"
import track from "./track"

const CoverageOverlay = (props) => {

  const {setCoverageOverlayOpen, showCoverage} = props;
  var currentOverlay;

  const handleCoverageOverlay = () => {
    track("coverage overlay", "button click", "close coverage overlay");

    setCoverageOverlayOpen(false);
  }

  return (
    <div id="coverage-overlay" className={`overlay ${showCoverage ? 'active' : ''}`}>
      <div className="overlay-content">
        <div id="patient-oic-quiz-close" className="overlay-close-btn" onClick={handleCoverageOverlay}></div>
        <div className="overlay-top">
          <p className="large">Coverage for the #1&nbsp;prescribed&nbsp;oral<br />PAMORA just got even better<sup>1</sup></p>
          <img className="mobile" src={movantikNaloxegol} alt="" />
          <img className="desktop" src={movantikNaloxegolDesktop} alt="" />
          <div className="footnotes">
            <p>*Data as of June 2021.<sup>3</sup></p>
            <p>Plans and coverage may vary and are subject to change. General information only, not a guarantee of coverage. Coverage and status do not imply efficacy or safety.</p>
            <p>PAMORA=peripherally acting mu-opioid receptor antagonist; PDP=prescription drug plan.</p>
            <p><strong>References: 1.</strong> Data on file. Prescription data. Valinor Pharma, LLC; 2023. <strong>2.</strong> Data on file. Prime Therapeutics formulary coverage. Valinor Pharma, LLC; 2023. <strong>3.</strong> Data on file. Cigna formulary coverage. Valinor Pharma, LLC; 2023.</p>
          </div>
        </div>
        <div className="overlay-bottom">
          <h2>SELECT IMPORTANT SAFETY INFORMATION ABOUT MOVANTIK</h2>
          <ul>
            <li>MOVANTIK<sup>&reg;</sup> (naloxegol) is contraindicated in:</li>
            <ul>
              <li>Patients with known or suspected gastrointestinal (GI) obstruction and patients at risk of recurrent obstruction, due to the potential for GI perforation</li>
              <li>Patients receiving strong CYP3A4 inhibitors (eg, clarithromycin, ketoconazole) because these medications can significantly increase exposure to naloxegol which may precipitate opioid withdrawal symptoms</li>
            </ul>
          </ul>
          <p className="bold">Click here for additional <a href="javascript:void(0);" target="_blank">Important Safety Information</a> and full <a href="javascript:void(0);" target="_blank">Prescribing Information</a> for MOVANTIK.</p>
        </div>
      </div>

    </div>
  )
}

export default CoverageOverlay
