import React, {useState} from "react"
import movantikLogo from "../images/movantik-logo.png"
import track from "./track"

const QuizOverlay = (props) => {

	const {showQuiz, setQuizOverlayOpen} = props;
	var currentOverlay, thisSlide, nextSlide;

	const handleQuizOverlay = () => {
		track("quiz overlay", "button click", "close quiz overlay")

		setQuizOverlayOpen(false);
		setTimeout(function(){
			currentOverlay = document.getElementById('quiz-overlay');
			thisSlide = currentOverlay.querySelector('.quiz-screen.current');
			thisSlide.classList.remove("current");
			currentOverlay.querySelector('.screen1').classList.add("current");
			var optionButtons = document.getElementsByClassName('option-btn');
			for (var i = 0; i < optionButtons.length; i++){
				optionButtons[i].classList.remove('selected');
			}
		},200);
	}

	const newScreen = (event) => {

		currentOverlay = document.getElementById('quiz-overlay');
		thisSlide = currentOverlay.querySelector('.quiz-screen.current');
		nextSlide = thisSlide.nextElementSibling;
		var optionButtons = thisSlide.querySelectorAll('.option-btn');
		console.log("thisSlide",thisSlide)
		if (optionButtons.length > 0){

			for ( var c = 0; c < optionButtons.length; c ++ ) {
				console.log("optionButtons[c].classList",optionButtons[c].classList)
				if (optionButtons[c].classList.contains('selected')) {
					//CHECK TO SEE VALUE OF SELECTED
					if (thisSlide.classList.contains('screen2')) {
						//YOU ARE ON THE FIRST QUESTION SCREEN
						if (optionButtons[c].classList.contains('no')) {
							//GO TO SCREEN 3
							nextSlide = currentOverlay.querySelector('.quiz-screen.screen3');
						} else {
							//GO TO SCREEN 4
							nextSlide = currentOverlay.querySelector('.quiz-screen.screen4');
						}
					} else if (thisSlide.classList.contains('screen4')) {
						if (optionButtons[c].classList.contains('four')) {
							nextSlide = currentOverlay.querySelector('.quiz-screen.screen5');

						} else {
							nextSlide = currentOverlay.querySelector('.quiz-screen.screen6');

						}
					}
					
					thisSlide.classList.remove("current");
					nextSlide.classList.add("current");
					track("quiz overlay", "button click", "submit")

				}
			}
		} else {
			
			//NO SELECT BUTTONS
			track("quiz overlay", "button click", "begin the quiz")
			if (thisSlide.classList.contains('screen1') || thisSlide.classList.contains('screen3')) {
				nextSlide = currentOverlay.querySelector('.quiz-screen.screen2');


			} else if (thisSlide.classList.contains('screen5')) {
				nextSlide = currentOverlay.querySelector('.quiz-screen.screen4');

			}
			thisSlide.classList.remove("current");
			nextSlide.classList.add("current");
		}
	}

	const selectOption = (event) => {
		var thisButton = event.currentTarget;
		currentOverlay = document.getElementById('quiz-overlay');

		thisSlide = currentOverlay.querySelector('.quiz-screen.current');
		if (thisSlide.classList.contains('screen6')) {
			if (thisButton.classList.contains('selected')) {
				thisButton.classList.remove('selected');

			} else {
				thisButton.classList.add('selected');

			}
		} else {
			track("quiz overlay", "button click", "option selected")
			var optionButtons = document.getElementsByClassName('option-btn');
			for (var i = 0; i < optionButtons.length; i++){
				optionButtons[i].classList.remove('selected');
			}
			thisButton.classList.add('selected');
		}

	}

	return (
		<div id="quiz-overlay" className={`overlay ${showQuiz ? 'active' : ''}`}>
			<div className="overlay-content">
				<div className="overlay-header">
					<div id="patient-oic-quiz-close" className="overlay-close-btn" onClick={handleQuizOverlay}></div>
					<p>Think you have <span className="nowrap">opioid-induced</span> constipation?</p>
				</div>
				<div className="quiz-screen screen1 current">
					<p className="quiz-screen-title">Think you have <span className="nowrap">opioid-induced</span> constipation?</p>
					<div id="patient-oic-quiz-begin" className="quiz-btn begin" onClick={newScreen}>
						<div className="quiz-btn-text">Begin the quiz</div>
					</div>
				</div>
				<div className="quiz-screen screen2">
					<p className="quiz-screen-title">Are you currently taking any opioid pain medication(s) for chronic <span className="nowrap">non-cancer</span> pain?</p>
					<p className="main-copy">Select one</p>
					<div className="quiz-buttons">
						<div id="patient-oic-quiz-screen2-yes" className="quiz-btn option-btn yes" onClick={selectOption}>
							<div className="quiz-btn-text">Yes</div>
						</div>
						<div id="patient-oic-quiz-screen2-no" className="quiz-btn option-btn no" onClick={selectOption}>
							<div className="quiz-btn-text">No</div>
						</div>
						<div id="patient-oic-quiz-screen2-notsure" className="quiz-btn option-btn not-sure" onClick={selectOption}>
							<div className="quiz-btn-text">Not sure</div>
						</div>
					</div>
					<div id="patient-oic-quiz-screen2-submit" className="quiz-btn submit" onClick={newScreen}>Submit</div>
				</div>

				<div className="quiz-screen screen3">
					<p className="main-copy">MOVANTIK is meant for people taking opioids in order to treat <span className="nowrap">opioid-induced</span> constipation in adults with chronic non-cancer pain. </p>
					<p className="main-copy">If you’re experiencing constipation, talk to your doctor about your options for treatment.<sup>2,3</sup></p>
					<div id="patient-oic-quiz-screen3-back" className="quiz-btn back"  onClick={newScreen}>
						<div className="quiz-btn-text">Go back</div>
					</div>

				</div>

				<div className="quiz-screen screen4">
					<p className="quiz-screen-title">How many bowel movements do&nbsp;you have per week?</p>
					<p className="main-copy">Select one</p>
					<div className="quiz-buttons">
						<div id="patient-oic-quiz-screen4-one" className="quiz-btn option-btn one" onClick={selectOption}>
							<div className="quiz-btn-text">&le;1</div>
						</div>
						<div id="patient-oic-quiz-screen4-twothree" className="quiz-btn option-btn two" onClick={selectOption}>
							<div className="quiz-btn-text">2-3</div>
						</div>
						<div id="patient-oic-quiz-screen4-fourmore" className="quiz-btn option-btn four" onClick={selectOption}>
							<div className="quiz-btn-text">4 or more</div>
						</div>
					</div>
					<div id="patient-oic-quiz-screen4-submit" className="quiz-btn submit" onClick={newScreen}>
						<div className="quiz-btn-text">Submit</div>
					</div>
				</div>


				<div className="quiz-screen screen5">
					<p className="main-copy">MOVANTIK is for the treatment of opioid-induced constipation in adults with chronic <span className="nowrap">non-cancer</span> pain. Having less than 3 bowel movements per week while taking opioids can be a symptom of <span className="nowrap">opioid-induced constipation.</span> </p>
					<p className="main-copy">If you’re experiencing constipation, talk to your doctor<br/>about your options for treatment.<sup>2,3</sup></p>
					<div id="patient-oic-quiz-screen5-back" className="quiz-btn back" onClick={newScreen}>
						<div className="quiz-btn-text">Go back</div>
					</div>

				</div>

				<div className="quiz-screen screen6">
					<p className="quiz-screen-title">When completing a bowel movement, have you experienced any of the following?</p>
					<p className="main-copy">Select all that apply</p>
					<div className="quiz-buttons">
						<div id="patient-oic-quiz-screen6-strain" className="quiz-btn option-btn strain small" onClick={selectOption}>
							<div className="quiz-btn-text">Straining</div>
						</div>
						<div id="patient-oic-quiz-screen6-hard" className="quiz-btn option-btn hard small" onClick={selectOption}>
							<div className="quiz-btn-text">Hard or lumpy<br/>stools</div>
						</div>
						<div id="patient-oic-quiz-screen6-incomplete" className="quiz-btn option-btn incomplete small" onClick={selectOption}>
							<div className="quiz-btn-text">Sense of incomplete evacuation</div>
						</div>
					</div>
					<div id="patient-oic-quiz-screen6-submit" className="quiz-btn submit" onClick={newScreen}>
						<div className="quiz-btn-text">Submit</div>
					</div>
				</div>	

				<div className="quiz-screen screen7">
					<img src={movantikLogo} className="quiz-logo" alt="Movantik" />
					<p className="quiz-screen-title">You may have <span className="nowrap">opioid-induced</span> constipation.<sup>1-3</sup></p>
					<p className="main-copy">Talk to you doctor about your symptoms and learn how<br/>MOVANTIK may be able to help.</p>
					<div className="end-matter">
						<div className="references"><span className="bold">References: 1.</span>&nbsp;Brock C, Olesen SS, Olesen AE, Frøkjaer JB, Andresen T, Drewes AM. Opioid-induced bowel dysfunction: pathophysiology and management. <span className="italic">Drugs.</span> <span className="nowrap">2012;72:1847-1865.</span> <span className="bold">2.</span>&nbsp;MOVANTIK<sup>&reg;</sup> (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">3.</span>&nbsp;Chey WD, Webster L, Sostek M, Lappalainen J, Barker PN, Tack J. Naloxegol for opioid-induced constipation in patients with noncancer pain. <span className="italic">N Engl J Med.</span> <span className="nowrap">2014;370:2387-2396.</span></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default QuizOverlay
