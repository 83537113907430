import React, { useEffect } from "react"
import IsiButton from "./isiButton"

// load the isi as fixed or inline depending on scroll position
var windowHeight,
  headerHeight,
  mainContentHeight,
  isi,
  isiHeight,
  contentTotal,
  scrollPosition,
  scrollCatch

function isiLoad() {
  isi = document.getElementById("isi");
  isiHeight = isi.offsetHeight;
  // isiHeight = 110;
}

function isiScroll() {
  windowHeight = window.innerHeight;
  scrollPosition = window.scrollY;
  headerHeight = document.querySelector("header").offsetHeight;
  mainContentHeight = document.querySelector("main").offsetHeight;
  contentTotal = Math.floor(headerHeight + mainContentHeight);
  scrollCatch = Math.ceil(scrollPosition + windowHeight - isiHeight);
  if (isi) {
    if (scrollCatch >= contentTotal) {
      isi.classList.add("inline");
    } else {
      isi.classList.remove("inline");
    }
  }
}

function handlePageLoad() {
  setTimeout(function () {
    isiLoad()
    isiScroll()
  }, 250)
}

function handleResize() {
  if (!isi.classList.contains("inline")) {
    isiLoad()
  }
  isiScroll()
}

const Isi = props => {
  const { pageType } = props

  useEffect(() => {
    handlePageLoad()
    window.addEventListener("resize", handleResize)
    window.addEventListener("scroll", isiScroll)
    return () => {
      window.removeEventListener("resize", handleResize)
      window.removeEventListener("scroll", isiScroll)
    }
  }, [])

  if (pageType !== "hcp") {
    // patient ISI
    return (
      <div id="isi">
        <div className="container">
          <IsiButton pageType={pageType} />
          <div className="isi-content">
            <h5>APPROVED USE FOR MOVANTIK</h5>
            <p>MOVANTIK is a prescription medicine used to treat constipation that is caused by prescription pain medicines called opioids, in adults with long-lasting (chronic) pain that is not caused by active cancer.</p>
            <h5 className="magenta">IMPORTANT SAFETY INFORMATION ABOUT MOVANTIK</h5>
            <ul className="main-bullet-list">
              <li className="bold">MOVANTIK may cause serious side effects, including:</li>
              <ul>
                <li>
                  <span className="bold">Opioid withdrawal.</span> You may have symptoms of opioid withdrawal during treatment with MOVANTIK, including sweating, chills, diarrhea, stomach pain, anxiety, irritability, and yawning. If you take methadone to treat your pain, you may be more likely to have stomach pain and diarrhea than people who do not take methadone. Tell your healthcare provider if you have any of these symptoms
                </li>
                <li>
                  <span className="bold">Severe stomach (abdominal) pain and/or diarrhea.</span> Severe stomach pain and diarrhea can happen when you take MOVANTIK. These problems can happen within a few days after you start taking MOVANTIK and can lead to hospitalization. Stop taking MOVANTIK and call your healthcare provider right away if you have severe stomach pain or diarrhea, or both severe stomach pain and diarrhea
                </li>
                <li>
                <span className="bold">Tear in your stomach or intestinal wall (perforation).</span> Stomach pain that is severe can be a sign of a serious medical condition. If you get stomach pain that gets worse or does not go away, stop taking MOVANTIK and get emergency medical help right away
                </li>
              </ul>
              <li className="bold">Do not take MOVANTIK if you:</li>
              <ul>
                <li>
                Have a bowel blockage (intestinal obstruction) or have a history of bowel blockage
                </li>
                <li>
                Are allergic to MOVANTIK or any of the ingredients in MOVANTIK
                </li>
              </ul>
              <li>
              MOVANTIK can interact with certain other medicines and cause side effects, including opioid withdrawal symptoms such as sweating, chills, diarrhea, stomach pain, anxiety, irritability, and yawning. Tell your healthcare provider or pharmacist before you start or stop any medicines during treatment with MOVANTIK
              </li>
              <li className="bold">
              Before you take MOVANTIK, tell your doctor about all of your medical conditions, including if you:
              </li>
              <ul>
                <li>
                Have any stomach or bowel (intestines) problems, including inflammation in parts of the large intestine (diverticulitis), or inflammation and injury of the intestines caused by reduced blood flow (ischemic colitis), or cancer of the stomach or bowel
                </li>
                <li>Have had recent surgery on the stomach or intestines</li>
                <li>Have any kidney or liver problems</li>
                <li>
                Are pregnant or plan to become pregnant. Taking MOVANTIK during pregnancy may cause opioid withdrawal symptoms in you or your unborn baby. Tell your healthcare provider right away if you become pregnant during treatment with MOVANTIK
                </li>
                <li>
                Are breastfeeding or plan to breastfeed. It is not known if MOVANTIK passes into your breast milk. Taking MOVANTIK while you are breastfeeding may cause opioid withdrawal in your baby. You and your healthcare provider should decide if you will take MOVANTIK or breastfeed. You should not breastfeed if you take MOVANTIK
                </li>
              </ul>
              <li>
                <span className="bold">Tell your doctor about all of the medicines you take,</span> including prescription and over-the-counter medicines, vitamins, and herbal supplements. Other medicines may affect the way MOVANTIK works
              </li>
              <li>
                <span className="bold">Especially tell your healthcare provider</span> if you take a medicine called bevacizumab
              </li>
              <li>
              Tell your healthcare provider if you stop taking your opioid pain medicine. If you stop taking your opioid pain medicine, you should also stop taking MOVANTIK
              </li>
              <li>
              Avoid eating grapefruit or drinking grapefruit juice during treatment with MOVANTIK
              </li>
              <li>
                <span className="bold">The most common side effects of MOVANTIK include:</span> stomach (abdomen) pain, diarrhea, nausea, gas, vomiting, headache, and excessive sweating. Tell your healthcare provider if you have any side effect that bothers you or that does not go away. These are not all the possible side effects of MOVANTIK
              </li>
              <li>It is not known if MOVANTIK is safe and effective in children</li>
            </ul>
            <p className="bold">
            You may report side effects to Valinor Pharma at <a href="tel:18775922337">1-877-592-2337</a> or FDA at <span className="nowrap"><a href="tel:18003321088">1-800-FDA-1088</a></span> or <a href="http://www.fda.gov/medwatch" target="_blank" id="isi-patient-fda">www.fda.gov/medwatch</a>.
            </p>
            <p className="bold">
              Click here for the <a href="/pdf/MovantikMedicationGuide.pdf" target="_blank" id="isi-patient-medguide">Medication Guide</a> and full <a href="/pdf/MovantikPrescribingInformation.pdf" target="_blank" id="isi-patient-pi">Prescribing Information</a> for MOVANTIK.
            </p>
            <p>
            Valinor Pharma, LLC, supports the responsible use of medications, including those containing opioids, as treatment options for patients with chronic non-cancer pain.
            </p>
            <p>This site is intended for US Consumers only.</p>
            <p>The information on this website should not take the place of talking with your doctor or health care professional. If you have any questions about your condition, or if you would like more information about MOVANTIK, or about constipation caused by opioid use for chronic non-cancer pain, talk to your doctor or pharmacist. Only you and your doctor can decide if MOVANTIK is right for you.</p>
            {/* <p>
              Valinor Pharma, LLC<br />
              150 N. Riverside Plaza – Suite 3480<br />
              Chicago, IL 60606<br />
              <a href="tel:18775922337">1-877-592-2337</a><br />
              <a href="mailto:info@valinorrx.com" id="isi-patient-email">info@valinorrx.com</a>
            </p> */}
          </div>
        </div>
      </div>
    )
  } else {
    // HCP ISI
    return (
      <div id="isi">
        <div className="container">
          <IsiButton pageType={pageType} />
          <div className="isi-content">
            <h5>INDICATIONS AND USAGE</h5>
            <p>MOVANTIK<sup>®</sup> (naloxegol) is an opioid antagonist indicated for the treatment of opioid-induced constipation (OIC) in adult patients with chronic non-cancer pain, including patients with chronic pain related to prior cancer or its treatment who do not require frequent (e.g., weekly) opioid dosage escalation.</p>
            <h5 className="magenta">IMPORTANT SAFETY INFORMATION ABOUT MOVANTIK</h5>
            <h5>CONTRAINDICATIONS</h5>
            <ul className="main-bullet-list">
              <li>
                MOVANTIK<sup>&reg;</sup> is contraindicated in:
              </li>
              <ul>
                <li>
                Patients with known or suspected gastrointestinal obstruction and patients at risk of recurrent obstruction, due to the potential for gastrointestinal perforation.
                </li>
                <li>
                Patients concomitantly using strong CYP3A4 inhibitors (e.g., clarithromycin, ketoconazole) because these medications can significantly increase exposure to naloxegol, which may precipitate opioid withdrawal symptoms such as hyperhidrosis, chills, diarrhea, abdominal pain, anxiety, irritability, and yawning.
                </li>
                <li>
                Patients with a known serious or severe hypersensitivity reaction to MOVANTIK or any of its excipients.
                </li>
              </ul>
              {/* <li>
                Symptoms consistent with opioid withdrawal, including hyperhidrosis, chills, diarrhea, abdominal pain, anxiety, irritability, and yawning, occurred in patients treated with MOVANTIK. Patients receiving methadone as therapy for their pain condition were observed in the clinical trials to have a higher frequency of GI adverse reactions that may have been related to opioid withdrawal than patients receiving other opioids. Patients with disruptions to the blood-brain barrier may be at increased risk for opioid withdrawal or reduced analgesia. These patients (eg, multiple sclerosis, recent brain injury, Alzheimer's disease, and uncontrolled epilepsy) were not enrolled in the clinical studies. Take into account the overall <span className="nowrap">risk-benefit</span> profile when using MOVANTIK in such patients. Monitor for symptoms of opioid withdrawal when using MOVANTIK in such patients
              </li>
              <li>
                Severe abdominal pain and/or diarrhea have been reported, generally within a few days of initiation of MOVANTIK. Monitor and discontinue if severe symptoms occur. Consider restarting MOVANTIK at 12.5&nbsp;mg once daily
              </li>
              <li>
                Cases of GI perforation have been reported with the use of peripherally acting opioid antagonists, including MOVANTIK. Postmarketing cases of GI perforation, including fatal cases, were reported when MOVANTIK was used in patients at risk of GI perforation (eg, infiltrative gastrointestinal tract malignancy, recent gastrointestinal tract surgery, diverticular disease including diverticulitis, ischemic colitis, or concomitantly treated with bevacizumab). Monitor for severe, persistent, or worsening abdominal pain; discontinue if this symptom develops
              </li>
              <li>
                Avoid concomitant use of moderate CYP3A4 inhibitors (eg, diltiazem, erythromycin, verapamil) because they may increase the risk of adverse reactions. Use of strong CYP3A4 inducers (eg, rifampin, carbamazepine, St. John’s Wort) is not recommended because they may decrease the efficacy of MOVANTIK. Avoid concomitant use of MOVANTIK with another opioid antagonist due to the increased risk of opioid withdrawal
              </li>
              <li>
                The use of MOVANTIK during pregnancy may precipitate opioid withdrawal in the pregnant woman and the fetus. Because of the potential for adverse reactions, including opioid withdrawal in breastfed infants, advise women that breastfeeding is not recommended during treatment with MOVANTIK
              </li>
              <li>
                The most common adverse reactions with MOVANTIK as compared to placebo in clinical trials were: abdominal pain (21% vs 7%), diarrhea (9% vs 5%), nausea (8% vs 5%), flatulence (6% vs 3%), vomiting (5% vs 4%), headache (4% vs 3%), and hyperhidrosis (3% vs &lt;1%)
              </li> */}
            </ul>
            <h5>WARNINGS AND PRECAUTIONS</h5>
            <ul>
              <li><span className="bold">Opioid withdrawal:</span> Clusters of symptoms consistent with opioid withdrawal, including hyperhidrosis, chills, diarrhea, abdominal pain, anxiety, irritability, and yawning, have occurred in patients treated with MOVANTIK. Additionally, patients receiving methadone as therapy for their pain condition were observed in clinical trials to have a higher frequency of gastrointestinal adverse reactions that may have been related to opioid withdrawal than patients receiving other opioids. Patients having disruptions to the blood-brain barrier may be at increased risk for opioid withdrawal or reduced analgesia. Consider the overall risk benefit in patients with disruptions to the blood-brain barrier. Monitor for symptoms of opioid withdrawal in such patients.</li>
              <li><span className="bold">Severe abdominal pain and/or diarrhea:</span> Reports of severe abdominal pain and/or diarrhea have been reported, some of which resulted in hospitalization. Most of the cases of severe abdominal pain were reported in patients taking the 25 mg dosage. Symptoms generally occurred within a few days of initiation of MOVANTIK. Monitor patients for the development of abdominal pain and/or diarrhea with MOVANTIK and discontinue therapy if severe symptoms occur. Consider restarting MOVANTIK at 12.5 mg once daily, if appropriate.</li>
              <li><span className="bold">Gastrointestinal perforation:</span> Cases of GI perforation have been reported with the use of peripherally acting opioid antagonists, including MOVANTIK. Postmarketing cases of GI perforation, including fatal cases, were reported when MOVANTIK was used in patients at risk of GI perforation (e.g., infiltrative gastrointestinal tract malignancy, recent gastrointestinal tract surgery, diverticular disease including diverticulitis, ischemic colitis, or concomitantly treated with bevacizumab). MOVANTIK is contraindicated in patients with known or suspected gastrointestinal obstruction or in patients at risk of recurrent obstruction. Take into account the overall risk-benefit profile when using MOVANTIK in patients with these conditions or other conditions which might result in impaired integrity of the gastrointestinal tract wall (e.g., Crohn’s disease). Monitor for severe, persistent, or worsening abdominal pain; discontinue MOVANTIK if this symptom develops.</li>
            </ul>
            <h5>ADVERSE REACTIONS</h5>
              <ul>
                <li>The most common adverse reactions in clinical trials (≥3%) are: abdominal pain, diarrhea, nausea, flatulence, vomiting, headache, and hyperhidrosis.</li>
              </ul>
              <h5>DRUG INTERACTIONS</h5>
              <ul>
                <li><span className="bold">Moderate CYP3A4 inhibitors (e.g., diltiazem, erythromycin, verapamil):</span> Increased naloxegol concentrations; avoid concomitant use; if unavoidable, reduce dosage to 12.5 mg once daily and monitor for adverse reactions.</li>
                <li><span className="bold">Strong CYP3A4 inducers (e.g., rifampin, carbamazepine, St. John’s Wort):</span> Decreased concentrations of naloxegol; concomitant use is not recommended.</li>
                <li><span className="bold">Other opioid antagonists:</span> Potential for additive effect and increased risk of opioid withdrawal; avoid concomitant use.</li>
              </ul>
              <h5>USE IN SPECIFIC POPULATIONS</h5>
              <ul>
                <li><span className="bold">Pregnancy and lactation:</span> The use of MOVANTIK during pregnancy may precipitate opioid withdrawal in the pregnant woman and the fetus. Because of the potential for adverse reactions, including opioid withdrawal in breastfed infants, advise women that breastfeeding is not recommended during treatment with MOVANTIK.</li>
                <li><span className="bold">Hepatic impairment:</span> Avoid use of MOVANTIK in patients with severe hepatic impairment, as the dosage in these patients has not been determined. No dosage adjustment is required for patients with mild or moderate hepatic impairment.</li>
                <li><span className="bold">Renal impairment:</span> In patients with creatinine clearance values &lt;60 mL/minute (i.e., moderate, severe, or end-stage renal disease), a lower starting dosage of 12.5 mg once daily is recommended. No dosage adjustment is needed in patients with mild renal impairment.</li>
              </ul>
              <p><span className="bold">To report SUSPECTED ADVERSE REACTIONS, contact Valinor at <span className="nowrap"><a href="tel:18775922337">1-877-592-2337</a></span> or FDA at <a href="tel:18003321088">1-800-FDA-1088</a> or <a href="http://www.fda.gov/medwatch" target="_blank" id="isi-patient-fda">www.fda.gov/medwatch</a>.</span></p>
              <p className="bold">
              Click here for the <a href="/pdf/MovantikMedicationGuide.pdf" target="_blank">Medication Guide</a> and full <a href="/pdf/MovantikPrescribingInformation.pdf" target="_blank" >Prescribing Information</a> for MOVANTIK.
              </p>
              {/* <p>Valinor Pharma, LLC, supports the responsible use of medications, including those containing opioids, as treatment options for patients with chronic non-cancer pain.</p> */}
              <p>This site is intended for US health care professionals only.</p>
              {/* <p>
              Valinor Pharma, LLC<br />
              150 N. Riverside Plaza – Suite 3480<br />
              Chicago, IL 60606<br />
              <a href="tel:18775922337">1-877-592-2337</a><br />
              <a href="mailto:info@valinorrx.com" id="isi-patient-email">info@valinorrx.com</a>
            </p> */}
          </div>
        </div>
      </div>
    )
  }
}

export default Isi
