import React from "react"
import backToTopArrow from "../images/arrow-up-pink.png"
import track from "./track"

const BackToTop = ({pageType}) => {

	const scrollToTop = () => {
		track(pageType + " Footer", "button click", "back to top")

		// fix for smooth scrolling in safari and older browsers
		var smoothScrollFeature = 'scrollBehavior' in document.documentElement.style;
		var i = parseInt(window.pageYOffset);
		if (!smoothScrollFeature) {
			var to = 0;
			var int = setInterval(function() {
				if (i < (to+20)) i -= 1;
				else if (i < (to+40)) i -= 3;
				else if (i < (to+80)) i -= 8;
				else if (i < (to+160)) i -= 18;
				else if (i < (to+200)) i -= 24;
				else if (i < (to+300)) i -= 40;
				else i -= 60;
				window.scroll(0, i);
				if (i <= to) clearInterval(int);
			}, 15);
		} else {
			// scrolling for all other current browsers
			window.scrollTo({top: 0, behavior: 'smooth'});
		}
	}

	return (
		<div className="backtotop">
			<div id={`footer-${pageType}-backtotop`} className="backtotop-btn" onClick={scrollToTop}>
				<div className="title">BACK TO TOP</div>
				<img src={backToTopArrow} className="arrow" alt="arrow" />
			</div>
		</div>
	)
}

export default BackToTop
