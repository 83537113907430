import React from "react"
import { Link } from "gatsby"
import BackToTop from "./backtotop"
import valinorLogo from "../images/valinor-logo.png"

const Footer = (props) => {

  const { pageType } = props;

  const sitemapUrl = pageType === 'hcp' ? '/hcp/sitemap/' : '/sitemap/';
  const privacyURL = pageType === 'hcp' ? '/hcp/privacy-policy/' : '/privacy-policy/';
  const contactURL = pageType === 'hcp' ? '/hcp/contact/' : '/contact/';
  const termsURL = pageType === 'hcp' ? '/hcp/terms-of-use/' : '/terms-of-use/';

  return (
    <footer>
      <BackToTop pageType={pageType} />
      <div className="container">
        <div className="footer-top">
          <div className="footer-links">
            <div className="footer-link">
              <Link to={contactURL} id={`footer-${pageType}-contact`}>Contact Us</Link>
            </div>
            <div className="footer-link">
              <Link to={privacyURL} id={`footer-${pageType}-privacy-policy`}>Privacy Policy</Link>
            </div>
            <div className="footer-link">
              <Link to={termsURL} id={`footer-${pageType}-contact`}>Terms of Use</Link>
            </div>
            <div className="footer-link">
              <Link to={sitemapUrl} id={`footer-${pageType}-sitemap`}>Site Map</Link>
            </div>
            <div className="footer-link">
              <a href="/pdf/MovantikPrescribingInformation.pdf" target="_blank" id={`footer-${pageType}-pi`}>Prescribing Information</a>
            </div>
            <div className="footer-link">
              <a href="/pdf/MovantikMedicationGuide.pdf" target="_blank" id={`footer-${pageType}-medguide`}>Medication Guide</a>
            </div>
          </div>
          <img src={valinorLogo} className="footer-logo" alt="Valinor Pharma, LLC" />
        </div>
        <div className="footer-bottom">
          <p>MOVANTIK is a registered trademark of the AstraZeneca group of companies.</p>
          <p>&copy;2024 Valinor Pharma, LLC. All rights reserved. <span className="nowrap">MOVA-018-01, 10/24</span></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
