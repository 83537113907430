import React from "react"
import { Link } from "gatsby"
import track from "./track"

const UpperNav = props => {
  const { pageType } = props
  
  const trackHeader = trackString => {
    track(pageType + " header - upper nav", "link click", trackString)
  }

  const returnSpecificInfo = () => {
    if (pageType === "hcp") {
      return (
        <div className="upper-nav">
          <div className="container">
            <div className="upper-nav-item pi"><a href="/pdf/MovantikPrescribingInformation.pdf" target="_blank" id="topnav-hcp-pi" onClick={e => trackHeader(`HCP Global Header-Prescribing Information`)}>Prescribing Information</a></div>
            <div className="upper-nav-item hcp-link"><Link to="/" id="topnav-hcp-patient-link" onClick={e => trackHeader(`HCP Global Header-Go to Patient Site`)}>Go to patient site</Link></div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="upper-nav">
          <div className="container">
            <div className="upper-nav-item pi"><a href="/pdf/MovantikPrescribingInformation.pdf" target="_blank" id="topnav-patient-pi" onClick={e => trackHeader(`DTC Global Header-Prescribing Information`)}>Prescribing Information</a></div>
            <div className="upper-nav-item hcp-link"><Link to="/hcp/" id="topnav-patient-hcp-link" onClick={e => trackHeader(`DTC Global Header-Go to HCP Site`)}>Go to HCP site</Link></div>
          </div>
        </div>
      )
    }
  }
  return (
    <>
      {returnSpecificInfo()}
    </>
  )
}

export default UpperNav
