import React from "react"
import { Link } from "gatsby"
import track from "./track"
import movantikLogo from "../images/movantik-logo.png"

class MenuButton extends React.Component {
  
  showNav(event) {
    var menuBtn = document.querySelector('.menu-btn');
    const navElement = document.querySelector('.nav-container');
    if (menuBtn.classList.contains('open')){
      menuBtn.classList.remove('open');
      navElement.classList.remove('open');
    } else {
      menuBtn.classList.add('open')
      navElement.classList.add('open');
    }
  }
  render() {
    return (
      <div onClick={this.showNav} className="menu-btn">
        <div className="line one"></div>
        <div className="line two"></div>
        <div className="line three"></div>
      </div>
    );
  }
  
}

const MainNav = props => {
  const { pageType } = props

  const trackHeader = trackString => {
    track(pageType + " main nav", "nav link click", trackString)
  }

  if (pageType !== "hcp") {
    //main nav for patient
    return (
      <div id="main-nav">
        <div className="container">
          <Link to="/" id="header-patient-logo" className="movantik-logo-link" onClick={e => trackHeader(`Go to Patient Home`)}>
            <img src={movantikLogo} className="header-logo" alt="Movantik" />
          </Link>
          <MenuButton />
          <div className="nav-container">
            <nav>
              <div className="nav-item about">
                <Link to="/about/" id="nav-patient-about"  onClick={e => trackHeader(`DTC About Movantik-About MOVANTIK main`)} className="nav-item-top">About MOVANTIK</Link>
              </div>
              <div className="nav-item burden-of-oic">
                <Link to="/burden-of-oic/" id="nav-burden-of-oic" onClick={e => trackHeader(`DTC Burden-of-OIC main`)} className="nav-item-top">
                  Burden of OIC
                </Link>
              </div>
              <div className="nav-item dosing">
                <Link to="/dosing/" id="nav-dosing" onClick={e => trackHeader(`DTC Dosing main`)} className="nav-item-top">
                  Dosing
                </Link>
              </div>
              <div className="nav-item savings">
                <Link to="/savings/" id="nav-patient-savings" onClick={e => trackHeader(`DTC Savings-Savings main`)} className="nav-item-top">
                  Savings
                </Link>
              </div>
            </nav>
            <div className="nav-bottom">
              <div className="nav-item"><a href="/pdf/MovantikMedicationGuide.pdf" target="_blank" id="nav-patient-pi" onClick={e => trackHeader(`DTC Mobile Only Nav-Prescribing Information`)}>Prescribing Information</a></div>
              <div className="nav-item"><Link to="/hcp/" id="nav-patient-hcp-link" onClick={e => trackHeader(`DTC Mobile Only Nav-Go to HCP Site`)}>Go to HCP Site</Link></div>
              {/* <div className="nav-item"><Link to="/patient-resources/resources/" id="nav-patient-signup" onClick={e => trackHeader(`DTC Mobile Only Nav-Sign up for emails`)}>Sign up for emails</Link></div> */}
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    // main nav for hcp
    return (
      <div id="main-nav">
        <div className="container">
          <Link to="/hcp" id="header-hcp-logo" onClick={e => trackHeader(`Go to HCP Home`)} className="movantik-logo-link">
            <img src={movantikLogo} className="header-logo" alt="Movantik" />
          </Link>
          <MenuButton />
          <div className="nav-container">
            <nav>
              <div className="nav-item burden-of-oic">
                <Link to="/hcp/burden-of-oic/" id="nav-hcp-burden-of-oic" onClick={e => trackHeader(`HCP Burden of OIC`)} className="nav-item-top">Burden of OIC</Link>
              </div>
              <div className="nav-item has-dropdown efficacy">
                <Link to="/hcp/efficacy/" id="nav-hcp-efficacy" onClick={e => trackHeader(`HCP Efficacy-Efficacy main`)} className="nav-item-top">Efficacy</Link>
              </div>
              <div className="nav-item safety">
                <Link to="/hcp/safety/" id="nav-hcp-safety" onClick={e => trackHeader(`GHCP Safety-Safety main`)} className="nav-item-top">
                  Safety
                </Link>
              </div>
              <div className="nav-item dosing">
                <Link to="/hcp/dosing/" id="nav-hcp-dosing" onClick={e => trackHeader(`HCP Dosing-Dosing main`)} className="nav-item-top">
                  Dosing
                </Link>
              </div>
              <div className="nav-item hcp-savings">
                <Link to="/hcp/savings/" id="nav-hcp-savings" onClick={e => trackHeader(`HCP Savings main`)} className="nav-item-top">
                  Patient Savings
                </Link>
              </div>
            </nav>
            <div className="nav-bottom">
              <div className="nav-item"><a href="/pdf/MovantikMedicationGuide.pdf" target="_blank" id="nav-hcp-pi" onClick={e => trackHeader(`HCP Mobile Only Nav-Prescribing Information`)}>Prescribing information</a></div>
              <div className="nav-item"><Link to="/" id="nav-hcp-patient-link" onClick={e => trackHeader(`HCP Mobile Only Nav-Go to Patient Site`)}>Go to patient site</Link></div>
              {/* <div className="nav-item"><Link to="" id="nav-hcp-signup" onClick={e => trackHeader(`HCP Mobile Only Nav-Sign up for emails`)}>Sign up for emails</Link></div> */}
              {/* <div className="nav-item"><Link to="" id="nav-hcp-request" onClick={e => trackHeader(`HCP Mobile Only Nav-Request Information`)}>Request information</Link></div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default MainNav
