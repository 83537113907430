import React from "react"
import { NavProvider, NavConsumer } from "./navContext"
import track from "./track"

const HcpConfirmationOverlay = ({ setCoverageOverlayOpen, pageType }) => {
  return (
    <NavConsumer>
      {value => {
        
        const { setHCPViewed, HCPViewed } = value

        const closeOverlay = () => {
          track("hcp confirmation overlay", "button click", "HCP Confirmation Overlay - Continue")

          setHCPViewed(true);

          
        }

        console.log(HCPViewed)

        const goBack = () => {
          track("hcp confirmation overlay", "button click", "HCP Confirmation Overlay - Go back")

          window.history.back()
        }
        return (
          <div
            id="hcp-overlay"
            className={`overlay ${(HCPViewed || pageType !== 'hcp') ? "" : "active"}`}
          >
            <div className="overlay-content">
              <p className="large">The information on the following site is intended for&nbsp;US healthcare professionals. Please choose:</p>
              <div className="overlay-columns">
                <div className="overlay-column">
                  <p>I am <span className="bold underline">not</span> a US healthcare professional</p>
                  <div id="hcp-confirmation-overlay-back" className="overlay-btn cancel" onClick={goBack}>GO BACK</div>
                </div>
                <div className="overlay-column">
                  <p>I am a US healthcare&nbsp;professional</p>
                  <div id="hcp-confirmation-overlay-continue" className="overlay-btn continue" onClick={closeOverlay}>CONTINUE</div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    </NavConsumer>
  )
}

export default HcpConfirmationOverlay